import { Box, Image, Paragraph, ResponsiveContext, Text } from 'grommet';
import { useContext } from 'react';

import { CharityEvent } from 'api/charityEventAPI';
import { Linkify } from 'components/shared/Linkify';
import { formatEventDate } from 'services/helpers';

interface EventDescriptionProps {
    event?: CharityEvent;
}

export const EventDescription = ({ event = undefined }: EventDescriptionProps) => {
    const size = useContext(ResponsiveContext);
    const isMobile = size === 'small';
    return (
        <Box direction={!isMobile ? 'row' : 'column'} margin={{ bottom: 'medium' }}>
            {event?.image && <Image width="325" height="225" src={event?.image} />}
            <Box align={!isMobile ? 'start' : 'center'} margin={{ left: 'medium' }}>
                <Text size="xxlarge">{event?.location}</Text>
                <Text size="medium">{event?.address}</Text>
                {event?.eventDate && <Text size="medium">{formatEventDate(event)}</Text>}
                {event?.description.split(/\r?\n/).map((text, i) => (
                    <Paragraph key={text || i} fill margin={{ bottom: '0' }}>
                        <Linkify content={text} />
                    </Paragraph>
                ))}
            </Box>
        </Box>
    );
};
