import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Decimal from 'decimal.js';
import { FieldArray, Form, Formik } from 'formik';
import {
    Box,
    Button,
    FormField,
    Image,
    MaskedInput,
    ResponsiveContext,
    Select,
    Spinner,
    Text,
    TextInput,
} from 'grommet';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import { CharityEvent, initiateEventRegistration } from 'api/charityEventAPI';
import { CheckoutForm } from 'components/shared/CheckoutForm';
import { ShirtSizes } from 'services/constants';
import { useHandleError } from 'services/errors';
import { getValidationMessage } from 'services/helpers';
import { AppRoutes } from 'services/routes';

const stripePromise = loadStripe(
    'pk_live_51KEMtyDfkCsbLu5aE67E6kd56sGCrcOGiswG0plJ0mvnhQgVXZbpqmXDgLriPwtJs1excHOaYe44JZqanQwUG4JU00a2lfkwJq',
);

// TODO: Make dynamic
const T_SHIRT = 'T-shirt';

const PHONE_NUMBER_REGEX = /^\(\d{3}\) \d{3}-\d{4}$/;

interface EventRegistrationFormProps {
    event?: CharityEvent;
}

export const EventRegistrationForm = ({ event = undefined }: EventRegistrationFormProps) => {
    const size = useContext(ResponsiveContext);
    const handleError = useHandleError();

    const { eventId } = useParams();

    const [clientSecret, setClientSecret] = useState('');

    const validationSchema = Yup.object({
        packages: Yup.array(
            Yup.object({
                packageId: Yup.string().required('Required'),
                qty: Yup.string().required('Required'),
                details: Yup.array(
                    Yup.object({
                        firstName: Yup.string().required('Required'),
                        lastName: Yup.string().when('packageName', {
                            is: (packageName: string) => packageName !== T_SHIRT,
                            then: (schema) => schema.required('Required'),
                        }),
                        email: Yup.string().email('Must be a valid email.').required('Required'),
                        phone: Yup.string()
                            .matches(PHONE_NUMBER_REGEX, 'Invalid phone number.')
                            .test(
                                'first-index-required',
                                'Required',
                                (val, { options }) =>
                                    (options as Yup.TestContext & { index: number }).index !== 0 ||
                                    !!val,
                            ),
                        shirtSize: Yup.string().when('packageName', {
                            is: T_SHIRT,
                            then: (schema) => schema.required('Required'),
                        }),
                    }),
                ).required(),
            }),
        ).required(),
    });

    const initialValues: Yup.InferType<typeof validationSchema> = {
        packages:
            event?.packages?.map((pkg) => ({
                packageId: pkg.packageId,
                qty: '0',
                details: [],
            })) ?? [],
    };

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={async (values) => {
                try {
                    if (eventId) {
                        const clientSecretResponse = await initiateEventRegistration({
                            eventId,
                            registeredPackages: values.packages.map((pkg) => ({
                                package_id: pkg.packageId,
                                qty: pkg.qty,
                                details: pkg.details,
                            })),
                        });
                        setClientSecret(clientSecretResponse.client_secret);
                    }
                } catch (err) {
                    handleError(
                        'An error occurred during registration. Please try again.',
                        err as Error,
                    );
                }
            }}
        >
            {(formik) => (
                <>
                    <Form>
                        {formik.values.packages.length > 0 && (
                            <>
                                <Text size="xlarge">Packages</Text>
                                <FieldArray name="packages">
                                    {(arrayHelpers) =>
                                        event?.packages?.map((pkg, index) => (
                                            <Box
                                                key={pkg.title}
                                                background={{ color: 'gray' }}
                                                round={{ size: 'small' }}
                                                pad="medium"
                                                margin={{ top: 'small', bottom: 'medium' }}
                                            >
                                                <Box direction="row" gap="small">
                                                    <FormField
                                                        width="90px"
                                                        disabled={!!clientSecret}
                                                        error={getValidationMessage(
                                                            `${arrayHelpers.name}.${index}.qty`,
                                                            formik,
                                                        )}
                                                    >
                                                        <Select
                                                            id={`${arrayHelpers.name}.${index}.qty`}
                                                            disabled={!!clientSecret}
                                                            options={Array.from(
                                                                Array(
                                                                    Math.min(
                                                                        pkg.remaining || 0,
                                                                        pkg.maxPackageCount || 0,
                                                                    ) + 1,
                                                                ).keys(),
                                                            ).map((num) => num.toString())}
                                                            value={
                                                                formik.values.packages[index].qty
                                                            }
                                                            onBlur={formik.handleBlur}
                                                            onChange={(e: { value: string }) =>
                                                                arrayHelpers.replace(index, {
                                                                    packageId:
                                                                        formik.values.packages[
                                                                            index
                                                                        ].packageId,
                                                                    qty: e.value,
                                                                    details: [
                                                                        ...Array<number>(
                                                                            Number(e.value),
                                                                        ),
                                                                    ].map(() => ({
                                                                        id: uuidv4(),
                                                                        packageName: pkg.name,
                                                                    })),
                                                                })
                                                            }
                                                        />
                                                    </FormField>
                                                    <Box
                                                        flex="grow"
                                                        direction="row"
                                                        gap="xsmall"
                                                        align="center"
                                                    >
                                                        <Text size="large">{pkg.title}</Text>
                                                        <Text size="large">
                                                            {pkg.cost.toLocaleString(undefined, {
                                                                style: 'currency',
                                                                currency: 'USD',
                                                                maximumFractionDigits: 0,
                                                            })}
                                                        </Text>
                                                    </Box>
                                                    {pkg.remaining && (
                                                        <Text color="purple">
                                                            {pkg.remaining} spots remaining
                                                        </Text>
                                                    )}
                                                </Box>
                                                {pkg.description
                                                    .split(/\r?\n/)
                                                    .map((text, i, arr) => (
                                                        <Box
                                                            key={text}
                                                            margin={{
                                                                left: '102px',
                                                                bottom:
                                                                    i < arr.length - 1
                                                                        ? 'small'
                                                                        : undefined,
                                                            }}
                                                        >
                                                            <Text>{text}</Text>
                                                        </Box>
                                                    ))}
                                            </Box>
                                        ))
                                    }
                                </FieldArray>
                                <Box
                                    direction="row"
                                    gap="medium"
                                    background={{ color: 'brand' }}
                                    round={{ size: 'small' }}
                                    pad="medium"
                                    margin={{ bottom: 'large' }}
                                >
                                    <Text size="large">
                                        {(
                                            formik.values.packages
                                                ?.reduce(
                                                    (prev, curr, i) =>
                                                        prev.add(
                                                            Decimal.mul(
                                                                curr.qty,
                                                                event?.packages?.[i].cost ?? 0,
                                                            ),
                                                        ),
                                                    new Decimal(0),
                                                )
                                                .toNumber() ?? 0
                                        ).toLocaleString(undefined, {
                                            style: 'currency',
                                            currency: 'USD',
                                            maximumFractionDigits: 0,
                                        })}
                                    </Text>
                                    <Text size="large">(Total)</Text>
                                    <Text size="large">Thank you for your support!</Text>
                                </Box>
                                <Box direction="row" gap="medium">
                                    <Box flex="grow" basis="0">
                                        {event?.packages?.map((pkg, i) =>
                                            formik.values.packages[i].details.map((item, j) => (
                                                <Box
                                                    // eslint-disable-next-line react/no-array-index-key
                                                    key={`${pkg.packageId}${j}`}
                                                    direction="column"
                                                    gap="small"
                                                    margin={{ bottom: 'medium' }}
                                                >
                                                    <Text size="xlarge">{`${pkg.name} ${
                                                        j + 1
                                                    }`}</Text>
                                                    <Box direction="row" gap="medium">
                                                        <Box flex="grow" basis="0">
                                                            <FormField
                                                                label="First Name"
                                                                disabled={!!clientSecret}
                                                                error={getValidationMessage(
                                                                    `packages.${i}.details.${j}.firstName`,
                                                                    formik,
                                                                )}
                                                            >
                                                                <TextInput
                                                                    id={`packages.${i}.details.${j}.firstName`}
                                                                    disabled={!!clientSecret}
                                                                    value={item.firstName}
                                                                    onBlur={formik.handleBlur}
                                                                    onChange={formik.handleChange}
                                                                />
                                                            </FormField>
                                                        </Box>
                                                        {pkg.name !== T_SHIRT && (
                                                            <Box flex="grow" basis="0">
                                                                <FormField
                                                                    label="Last Name"
                                                                    disabled={!!clientSecret}
                                                                    error={getValidationMessage(
                                                                        `packages.${i}.details.${j}.lastName`,
                                                                        formik,
                                                                    )}
                                                                >
                                                                    <TextInput
                                                                        id={`packages.${i}.details.${j}.lastName`}
                                                                        disabled={!!clientSecret}
                                                                        value={item.lastName}
                                                                        onBlur={formik.handleBlur}
                                                                        onChange={
                                                                            formik.handleChange
                                                                        }
                                                                    />
                                                                </FormField>
                                                            </Box>
                                                        )}
                                                    </Box>
                                                    <Box direction="row" gap="medium">
                                                        <Box flex="grow" basis="0">
                                                            <FormField
                                                                label="Email"
                                                                disabled={!!clientSecret}
                                                                info={
                                                                    j === 0
                                                                        ? 'Primary Contact for confirmation email'
                                                                        : undefined
                                                                }
                                                                error={getValidationMessage(
                                                                    `packages.${i}.details.${j}.email`,
                                                                    formik,
                                                                )}
                                                            >
                                                                <TextInput
                                                                    id={`packages.${i}.details.${j}.email`}
                                                                    type="email"
                                                                    disabled={!!clientSecret}
                                                                    value={item.email}
                                                                    onBlur={formik.handleBlur}
                                                                    onChange={formik.handleChange}
                                                                />
                                                            </FormField>
                                                        </Box>
                                                        {j === 0 && (
                                                            <Box flex="grow" basis="0">
                                                                <FormField
                                                                    label="Phone"
                                                                    disabled={!!clientSecret}
                                                                    error={getValidationMessage(
                                                                        `packages.${i}.details.${j}.phone`,
                                                                        formik,
                                                                    )}
                                                                >
                                                                    <MaskedInput
                                                                        id={`packages.${i}.details.${j}.phone`}
                                                                        mask={[
                                                                            { fixed: '(' },
                                                                            {
                                                                                length: 3,
                                                                                regexp: /^[0-9]{1,3}$/,
                                                                                placeholder: 'xxx',
                                                                            },
                                                                            { fixed: ')' },
                                                                            { fixed: ' ' },
                                                                            {
                                                                                length: 3,
                                                                                regexp: /^[0-9]{1,3}$/,
                                                                                placeholder: 'xxx',
                                                                            },
                                                                            { fixed: '-' },
                                                                            {
                                                                                length: 4,
                                                                                regexp: /^[0-9]{1,4}$/,
                                                                                placeholder: 'xxxx',
                                                                            },
                                                                        ]}
                                                                        disabled={!!clientSecret}
                                                                        value={item.phone}
                                                                        onBlur={formik.handleBlur}
                                                                        onChange={
                                                                            formik.handleChange
                                                                        }
                                                                    />
                                                                </FormField>
                                                            </Box>
                                                        )}
                                                        {pkg.name === T_SHIRT && (
                                                            <Box flex="grow" basis="0">
                                                                <FormField
                                                                    label="Shirt Size"
                                                                    disabled={!!clientSecret}
                                                                    error={getValidationMessage(
                                                                        `packages.${i}.details.${j}.shirtSize`,
                                                                        formik,
                                                                    )}
                                                                >
                                                                    <Select
                                                                        id={`packages.${i}.details.${j}.shirtSize`}
                                                                        placeholder="Select Size"
                                                                        disabled={!!clientSecret}
                                                                        options={ShirtSizes}
                                                                        value={
                                                                            formik.values.packages[
                                                                                i
                                                                            ].details[j].shirtSize
                                                                        }
                                                                        onBlur={formik.handleBlur}
                                                                        onChange={(e: {
                                                                            value: string;
                                                                        }) => {
                                                                            (async () => {
                                                                                await formik.setFieldValue(
                                                                                    `packages.${i}.details.${j}.shirtSize`,
                                                                                    e.value,
                                                                                );
                                                                            })();
                                                                        }}
                                                                    />
                                                                </FormField>
                                                            </Box>
                                                        )}
                                                    </Box>
                                                </Box>
                                            )),
                                        )}
                                    </Box>
                                    {size !== 'small' && (
                                        <Box width="33%">
                                            <Image src={event?.formImage} loading="lazy" />
                                        </Box>
                                    )}
                                </Box>
                                {!clientSecret &&
                                    (!formik.isSubmitting ? (
                                        <Button
                                            type="submit"
                                            primary
                                            size="small"
                                            label="CONTINUE TO PAYMENT"
                                            disabled={!(formik.isValid && formik.dirty)}
                                        />
                                    ) : (
                                        <Spinner size="medium" />
                                    ))}
                            </>
                        )}
                    </Form>
                    {clientSecret && (
                        <Elements
                            stripe={stripePromise}
                            options={{ clientSecret, appearance: { theme: 'stripe' } }}
                        >
                            <CheckoutForm
                                returnUrl={`${
                                    window.location.origin
                                }${AppRoutes.eventRegistrationConfirmation(
                                    eventId,
                                    encodeURIComponent(
                                        formik.values.packages?.find(
                                            ({ details }) => details.length > 0,
                                        )?.details?.[0]?.email ?? '',
                                    ),
                                )}`}
                            />
                        </Elements>
                    )}
                </>
            )}
        </Formik>
    );
};
