import HeaderLogo from 'assets/headerLogo.svg?react';
import {
    Box,
    Button,
    ButtonProps,
    Header as GrommetHeader,
    Menu as GrommetMenu,
    Layer,
    Nav,
    ResponsiveContext,
    Text,
    TextProps,
} from 'grommet';
import { Close, Menu } from 'grommet-icons';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { AppRoutes } from 'services/routes';

interface NavTextProps {
    title: string;
    route: string;
    size: TextProps['size'];
}

const NavText = ({ title, route, size }: NavTextProps) => {
    const location = useLocation();
    return (
        <Text size={size} color={location.pathname.startsWith(route) ? 'pink' : undefined}>
            {title}
        </Text>
    );
};

interface NavButtonProps extends NavTextProps {
    margin?: ButtonProps['margin'];
    setShowSidebar: (a: boolean) => void;
}

const NavButton = ({ title, route, size, margin = undefined, setShowSidebar }: NavButtonProps) => {
    const navigate = useNavigate();

    return (
        <Button
            key={title}
            onClick={() => {
                navigate(route);
                setShowSidebar(false);
            }}
            margin={margin}
        >
            <NavText title={title} route={route} size={size} />
        </Button>
    );
};

interface NavItemProps {
    title: string;
    route: string;
    options?: {
        title: string;
        route: string;
    }[];
}

const navItems: NavItemProps[] = [
    {
        title: 'ABOUT US',
        route: AppRoutes.about,
        options: [{ title: 'OUR STORY', route: AppRoutes.ourStory }],
    },
    { title: 'EVENTS', route: AppRoutes.events },
    {
        title: 'OUR IMPACT',
        route: AppRoutes.impact,
        options: [
            { title: 'MONEY RAISED', route: AppRoutes.moneyRaised },
            { title: 'CAUSES SUPPORTED', route: AppRoutes.causesSupported },
        ],
    },
];

const mobileNavItems = navItems.reduce(
    (prev, next) => [...prev, ...(Array.isArray(next.options) ? next.options : [next])],
    [] as NavItemProps[],
);

export const Header = () => {
    const navigate = useNavigate();

    const size = useContext(ResponsiveContext);
    const isMobile = size === 'small';

    const [showSidebar, setShowSidebar] = useState<boolean>(false);
    useEffect(() => {
        if (!isMobile) {
            setShowSidebar(false);
        }
    }, [isMobile]);

    return (
        <GrommetHeader background="brand" pad="medium" elevation="medium" gap="none">
            {!isMobile ? (
                <Nav flex="grow" basis="0" direction="row">
                    {navItems.map((item) => {
                        if (Array.isArray(item.options)) {
                            return (
                                <GrommetMenu
                                    key={item.title}
                                    dropProps={{
                                        align: {
                                            top: 'bottom',
                                            left: 'left',
                                        },
                                        elevation: 'xsmall',
                                    }}
                                    label={
                                        <NavText
                                            title={item.title}
                                            route={item.route}
                                            size="small"
                                        />
                                    }
                                    items={item.options.map((option) => ({
                                        key: option.title,
                                        label: (
                                            <NavText
                                                title={option.title}
                                                route={option.route}
                                                size="small"
                                            />
                                        ),
                                        onClick: () => {
                                            navigate(option.route);
                                            setShowSidebar(false);
                                        },
                                    }))}
                                    dropBackground="brand"
                                />
                            );
                        }
                        return (
                            <NavButton
                                key={item.title}
                                title={item.title}
                                route={item.route}
                                size="small"
                                margin={{
                                    right: 'small',
                                }}
                                setShowSidebar={setShowSidebar}
                            />
                        );
                    })}
                </Nav>
            ) : (
                <Box flex="grow" basis="0">
                    <Menu
                        size="large"
                        cursor="pointer"
                        onClick={() => setShowSidebar((prev) => !prev)}
                    />
                    {showSidebar && (
                        <Layer background="brand" position="left">
                            <Box direction="row" gap="large" margin="large">
                                <Close
                                    size="large"
                                    cursor="pointer"
                                    onClick={() => setShowSidebar(false)}
                                />
                                <Box flex="grow" gap="large">
                                    {mobileNavItems.map((item) => (
                                        <NavButton
                                            key={item.title}
                                            title={item.title}
                                            route={item.route}
                                            size="xlarge"
                                            setShowSidebar={setShowSidebar}
                                        />
                                    ))}
                                </Box>
                            </Box>
                        </Layer>
                    )}
                </Box>
            )}
            <Box height="xsmall" width="small">
                <HeaderLogo
                    onClick={() => navigate(AppRoutes.home)}
                    style={{ cursor: 'pointer' }}
                />
            </Box>
            <Box flex="grow" basis="0" direction="row" align="center" justify="end">
                <Button
                    primary
                    color="white"
                    size="small"
                    label="DONATE"
                    onClick={() => navigate(AppRoutes.donate)}
                />
            </Box>
        </GrommetHeader>
    );
};
